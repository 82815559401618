
export default {
  name: 'EmsOne',
  props: {
    image: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    buttonTitle: {
      type: String,
      default: '',
    },
  },
}
