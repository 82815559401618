
export default {
  name: 'LatestNewsTwoBlocks',
  props: {
    pushToTop: {
      type: Boolean,
      default: false,
    },
    services: {
      type: Array,
      default: () => [],
    },
  },
}
