
import { newsPosts } from '@/queries/news/newsPosts.gql'
export default {
  name: 'EMSLanding',

  async asyncData({ app, i18n, $config, $sentry }) {
    try {
      const { data } = await app.$gqlQuery({
        query: newsPosts,
        fetchPolicy: 'reload',
        variables: {
          limit: 6,
          domainId: $config.domainId,
          languageCode: i18n.locale,
        },
        context: {
          headers: {
            DomainUrl: $config.domain.replace('https://', ''),
          },
        },
      })

      if (
        !Array.isArray(data?.newsPosts?.data) ||
        !data?.newsPosts?.data?.length
      ) {
        console.error('No data for latestNews')
      }

      return {
        latestNews: data?.newsPosts?.data || [],
      }
    } catch (ex) {
      console.error('teltonika-ems-landing asyncData error', ex)
    }
  },
  data() {
    return {
      pageFiles: {},
      infoBlocksData: [
        {
          title: 'front.home.agile',
          text: 'front.home.agile.content',
        },
        {
          title: 'front.home.reliable',
          text: 'front.home.reliable.content',
        },
        {
          title: 'front.home.devoted',
          text: 'front.home.devoted.content',
        },
      ],
      latestNews: null,
    }
  },
  computed: {
    bannerImage() {
      return this.pageFiles['ems.home.banner.image'] || {}
    },
    twoColumnBlocksData() {
      return {
        type: 'image',
        image: this.pageFiles['ems.landing.plastic-injection'],
      }
    },
    heroImage() {
      return this.pageFiles['ems.landing.hero']
    },
    latestNewsTwoBlocksData() {
      return [
        {
          image: this.pageFiles['ems.landing.electronics-design'],
          title: this.$i18n.t('front.home.card1.title'),
          description: this.$i18n.t('front.home.card1.content'),
          to: this.localePath(this.$i18n.t('front.home.card1.link')),
          buttonTitle: this.$i18n.t('front.home.card1.cta'),
        },
        {
          image: this.pageFiles['ems.landing.electronics-assembly'],
          title: this.$i18n.t('front.home.card2.title'),
          description: this.$i18n.t('front.home.card2.content'),
          to: this.localePath(this.$i18n.t('front.home.card2.link')),
          buttonTitle: this.$i18n.t('front.home.card2.cta'),
        },
      ]
    },
  },
  created() {
    this.pageFiles = this.$store.state?.page?.content?.files || {}
  },
}
