
export default {
  name: 'InfoBlocks',
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
}
