
export default {
  name: 'HeroNine',
  props: {
    textToTop: {
      type: Boolean,
      default: false,
    },
    bgImageLink: {
      type: String,
      default: '/images/hero-homepage.jpg',
    },
    bgImageLinkWebp: {
      type: String,
      default: '',
    },
    bgFromCrm: {
      type: [Object, Boolean],
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: 'h-64 md:h-[645px]',
    },
    gradientClass: {
      type: String,
      default: 'c-hero-gradient',
    },
    containerClass: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
}
